import React from "react";
import Layout from "../components/Layout";
import Image from "../components/Image";
import { Container } from "../components/Sections";
import ReactMarkdown from 'react-markdown'; 
const EventsPageTemplate = ({
  title,
  eventList,
}) => {
  return (
    <Layout>
      <Container>
        <div className="mb-12 text-xl text-center"><p>{title}</p></div>
        <div className="-mb-20">
          {eventList && eventList.map((item, index) => (
            <div key={index}>
              <div className="grid gap-16 mb-20 md:grid-cols-2">
                <div className={`flex ${index % 2 === 0 ? '' : 'md:order-2'}`}>
                  <Image
                    image={item.image}
                    alt=""
                    placeholder="dominantColor"
                    imgClassName="object-cover object-center w-full"
                  />
                </div>
                <div className={`prose prose-lg`}>
                  <strong className={`mb-4 block`}>{item.heading}</strong>
                  <ReactMarkdown>{item.text}</ReactMarkdown>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default EventsPageTemplate;
