import React from "react"
import { graphql } from "gatsby"

import EventsPageTemplate from "./EventsPageTemplate"
const EventsPage = ({ data }) => {
  const { frontmatter: fm } = data.markdownRemark

  return (
    <>
    
      <EventsPageTemplate
        title={fm.title}
        description={fm.description}
        text={fm.text}
        eventList={fm.eventList}
      />
    </>
  )
}

export default EventsPage


export const Head = ({ data }) => (
    <>
      <title>{data.markdownRemark.frontmatter.title}</title>
      <meta name="description" content={data.markdownRemark.frontmatter.description} />
    </>
)

export const pageQuery = graphql`
  query EventsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        eventList{
          heading
          text
          image {
            childImageSharp {
              gatsbyImageData(
                width: 500
                height: 600
                formats:[AUTO, WEBP, AVIF]
                placeholder: DOMINANT_COLOR
                transformOptions:{
                  fit: INSIDE
                }
              )
            }
          }
        }
        title
        description
      }
    }
  }
`
